














import PagedCards from '@/components/Controls/Data/PagedCards.vue'
import EmptyState from '@/components/Controls/EmptyState.vue'
import BlogCard from '@/components/Controls/Types/BlogCard.vue'
import BlogFilter from 'truemarket-modules/src/filters/BlogFilter'
import { BlogModel, BlogPostStatus, BlogPostType } from 'truemarket-modules/src/models/api/blogs'
import { PagedResultSet } from 'truemarket-modules/src/models/api/common'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    EmptyState,
    PagedCards,
    BlogCard
  }
})
export default class BlogIndex extends Vue {
  private blogData: PagedResultSet<BlogModel> | null = null

  mounted () {
    Services.API.Blogs.GetBlogs(new BlogFilter({
      BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId,
      Type: BlogPostType.Blog,
      Status: BlogPostStatus.None
    })).then((blogs) => {
      this.blogData = blogs

      console.log(blogs)
    })
  }

  newBlogPost () {
    this.$router.push('/dashboard/blogs/new')
  }
}
