













import { BlogModel } from 'truemarket-modules/src/models/api/blogs'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BlogCard extends Vue {
  @Prop({ required: true })
  private readonly blog!: BlogModel
}
