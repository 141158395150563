import { BlogPostStatus, BlogPostType } from "../models/api/blogs";
import PagedFilterBase from "./PagedFilterBase";

class BlogFilter extends PagedFilterBase {
  BusinessBranchId?: string;
  UserId?: string;
  Status = BlogPostStatus.None;
  Type = BlogPostType.None;
  RequireDisplayPublic = false;
  RequireDisplayMembers = false;

  constructor(init?: Partial<BlogFilter>) {
    super()
    
    if (init) Object.assign(this, init);
  }
}

export default BlogFilter