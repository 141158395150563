












import { PagingData } from 'truemarket-modules/src/models/api/common'
import { Component, Vue, Prop } from 'vue-property-decorator'
import Paging from './Paging.vue'

@Component({
  components: {
    Paging
  }
})
export default class PagedCards extends Vue {
  @Prop()
  private readonly dataset!: PagingData;
}
