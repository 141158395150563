
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Paging extends Vue {
  @Prop({ required: true })
  private readonly page!: number;

  @Prop({ required: true })
  private readonly pages!: number;

  @Prop({ default: 2 })
  private readonly lookBack!: number;

  @Prop({ default: 3 })
  private readonly lookAhead!: number;

  get pageRange () {
    const pages: number[] = []

    const startPage = Math.max(1, this.page - this.lookBack)
    const endPage = Math.min(this.pages, this.page + this.lookAhead)

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }

    return pages
  }

  goto (page: number) {
    this.$emit('paged', page)
  }
}
