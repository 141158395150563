class FilterBase {
  ToQueryParams(): Record<string, string> {
    const items: Record<string, string> = {}

    Object.keys(this).forEach((k) => {
      const item = Object.getOwnPropertyDescriptor(this, k);

      if (item?.value !== undefined && item?.value !== null) items[encodeURIComponent(k)] = encodeURIComponent(item.value.toString())
    });

    return items
  }
}

export default FilterBase